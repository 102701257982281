
.purchase-detail {
  box-sizing: border-box;
  height: 100%;
  padding: 40px;
  background: #f7f9fb;
  .page-title {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333;
    margin: 20px 0;
    line-height: 1;
  }
}
